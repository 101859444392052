import { createContext, useState, ReactNode, useEffect } from "react";
import * as APIProposta from "../services/APIProposta";

interface CityStatePropostaContextProps {
  cities: string[];
  states: string[];
  city: { value: string; label: string } | null;
  setCity: (value: { value: string; label: string } | null) => void;
  setCities: React.Dispatch<React.SetStateAction<string[]>>;
  setStates: React.Dispatch<React.SetStateAction<string[]>>
  state: { value: string; label: string } | null;
  setState: (value: { value: string; label: string } | null) => void;
  getStates: () => Promise<void>;
  getCities: (state: string[] | null) => Promise<void>
  selectCities: { value: string; label: string }[];
  selectStates: { value: string; label: string }[];
}

export const CityStatePropostaContext = createContext<CityStatePropostaContextProps>({
  cities: [],
  states: [],
  city: null,
  setCity: (value) => { },
  state: null,
  setState: (value) => { },
  getStates: async () => { },
  getCities: async (state) => { },
  selectCities: [],
  selectStates: [],
  setCities: (value) => { },
  setStates: (value) => { }
});

interface CityStatePropostaProviderProps {
  children: ReactNode;
}

export const CityStatePropostaProvider: React.FC<CityStatePropostaProviderProps> = ({ children }) => {
  const [cities, setCities] = useState<string[]>([]);
  const [states, setStates] = useState<string[]>([]);
  const [city, setCity] = useState<{ value: string; label: string } | null>(null);
  const [state, setState] = useState<{ value: string; label: string } | null>(null);


  const selectCities = cities.map((city) => ({
    value: city,
    label: city,
  }));

  const selectStates = states.map((state) => ({
    value: state,
    label: state,
  }));

  const getStates = async () => {
    const response = await APIProposta.getStates();
    const states = response.data.estados;

    setStates(states);
  };

  const getCities = async (state: string[] | null) => {
    if (state !== null) {
      const response = await APIProposta.getCities(state);
      const cities = response.data.cidades;
      setCities(cities);
    } else {
      setCities([]);
    }
  };

  useEffect(() => {
    getStates();
  }, []);

  return (
    <CityStatePropostaContext.Provider
      value={{
        cities,
        setCities,
        states,
        setStates,
        city,
        state,
        setCity,
        setState,
        getCities,
        getStates,
        selectCities,
        selectStates,
      }}
    >
      {children}
    </CityStatePropostaContext.Provider>
  );
};
