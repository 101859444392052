import React, { useEffect, useState } from 'react';
import styles from './Checkbox.module.css';
import { makeid } from '../../utils';

interface CheckboxProps {
  check?: boolean;
  label?: string;
  onChange?: (isChecked: boolean) => void;
  className?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({
  check = false,
  label = '',
  onChange = () => {},
  className = '',
}) => {
  const [id, setId] = useState<string>('');

  useEffect(() => {
    const localId = makeid(10);
    setId(localId);
  }, []);

  return (
    <div className={`${styles.checkboxDiv} ${className}`}>
      <div className={styles.checkbox}>
        <input
          type="checkbox"
          id={id}
          checked={check}
          onChange={(evt) => onChange(evt.target.checked)}
        />
        <label htmlFor={id}></label>
      </div>
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

export default Checkbox;
