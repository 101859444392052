import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import PrintProposta from "./pages/proposta/PrintProposta";
import Formulario from "./pages/formulario/Formulario";
import Login from "./pages/login/Login";

const PrivateRoute = ({ element }: any) => {
    const isAuthenticated = localStorage.getItem('authToken') !== null;

    return isAuthenticated ? element : <Navigate to="/login" />;
};

const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<PrintProposta />} />
                <Route path="/login" element={<Login />} />
                <Route
                    path="/formulario"
                    element={
                        <React.Fragment>
                            <PrivateRoute
                                element={<Formulario />}
                            />
                        </React.Fragment>
                    }
                />
            </Routes>
        </BrowserRouter>
    );
}

export default Router;
