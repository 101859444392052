import React, { useEffect } from 'react';
import InputMask from 'react-input-mask';

import styles from './InputWhatsapp.module.css';

import whatsappIcon from '../../assets/icons/whatsapp.webp';
import whatsappVerde from '../../assets/icons/whatsapp-verde.webp';

interface InputWhatsappProps {
  whatsapp: string;
  onChange: (value: string) => void;
  className?: string;
  isValidWhatsapp: boolean;
  setIsValidWhatsapp: (isValid: boolean) => void;
}

const InputWhatsapp: React.FC<InputWhatsappProps> = ({
  whatsapp = '',
  onChange = () => {},
  className = '',
  isValidWhatsapp = false,
  setIsValidWhatsapp = () => {},
}) => {
  useEffect(() => {
    const regexToPhone = /^\([\d]{2}\)\s[\d]{5}\-[\d]{4}$/;
    const test = whatsapp.match(regexToPhone);
    setIsValidWhatsapp(!!test);
  }, [whatsapp]);

  return (
    <div className={`${styles.inputPhone} ${isValidWhatsapp ? styles.isValidWhatsapp : ''} ${className}`}>
      <img src={isValidWhatsapp ? whatsappVerde : whatsappIcon} alt="Whatsapp icone" />
      <InputMask
        type="tel"
        placeholder="Coloque seu Whatsapp"
        mask="(99) 99999-9999"
        maskChar=" "
        value={whatsapp}
        onChange={(evt) => onChange(evt.target.value)}
      />
    </div>
  );
};

export default InputWhatsapp;
