
export const makeid = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

export const navigateToExternal = (url, blank = true) => {
    if (blank) {
        window.open(url, '_blank').focus();
        return;
    }
    window.open(url).focus();
};
