import React, { useContext, useState, useEffect } from 'react';
import styles from './Login.module.css';
import { ToastContainer, toast } from 'react-toastify';
import Button from '../../components/ctaButton/CtaButton';
import passwordIcon from '../../assets/icons/key-round.svg';
import passwordIconVerde from '../../assets/icons/key-round-green.svg';
import InputName from '../../components/inputName/InputName';
import LoginContext from '../../context/LoginContext';
import { navigateToExternal } from '../../utils';
import { useNavigate } from 'react-router-dom';
import SimboloPlin from "../../assets/simbolo-plin.png"

const Login = () => {
    const navigate = useNavigate();

    const [nome, setNome] = useState("");
    const [onValidateNome, setOnValidateNome] = useState(false);

    const [senha, setSenha] = useState("");
    const [onValidateSenha, setOnValidateSenha] = useState(false);

    const [loading, setLoading] = useState(false);

    const { sendForm } = useContext(LoginContext);

    useEffect(() => {
        const isAuthenticated = localStorage.getItem('authToken') !== null;
        if (isAuthenticated) {
            navigate("/formulario");
        }
    }, [navigate]);

    const handleClickButton = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        if (loading) return;

        if (!onValidateNome) {
            toast.warn("Por favor insira o usuário!");
            return;
        }

        if (!onValidateSenha) {
            toast.warn("Por favor insira a senha!");
            return;
        }

        const dataToSend = {
            login: nome,
            pass: senha
        }

        setLoading(true);

        sendForm(dataToSend)
            .then(() => {
                setNome("");
                setSenha("");
                toast.success("Autenticado com sucesso!")
                navigate("/formulario");
            })
            .catch(() => toast.error("Usuário ou senha inválido!"))
            .finally(() => setLoading(false));
    };

    return (
        <div className={styles.container}>
            <form action="">
                <div className={styles.loginForm}>
                    <div className={styles.headerForm}>
                        <img
                            src={SimboloPlin}
                            alt="Simbolo Plin"
                            className={styles.simboloPlin}
                        />
                        <h1>Login</h1>
                    </div>
                    <div className={styles.inputs}>
                        <InputName value={nome} placeholder='Usuário' onChange={setNome} isValidName={onValidateNome} setIsValidName={setOnValidateNome} />
                        <InputName value={senha} img={passwordIcon} imgGreen={passwordIconVerde} type="password" placeholder="Senha" onChange={setSenha} isValidName={onValidateSenha} setIsValidName={setOnValidateSenha} />
                    </div>
                    <Button label="Entrar" onClick={handleClickButton} disable={loading} className={styles.loginButton} />
                </div>
            </form>
        </div >
    );
};

export default Login;
