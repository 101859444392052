import React, { useEffect } from "react";
import styles from "./InputQtdPlacas.module.css";

import InputMask from "react-input-mask";

import solarPanelIcon from "../../assets/solar-panel-icon.svg";
import solarPanelIconGreen from "../../assets/solar-panel-icon-green.svg";

interface InputQtdPlacasProps {
  value?: string;
  onChange?: (value: string) => void;
  className?: string;
  isValidPlacas?: boolean;
  setIsValidPlacas?: (isValid: boolean) => void;
}

const InputQtdPlacas: React.FC<InputQtdPlacasProps> = ({
  value = "",
  onChange = () => {},
  className = "",
  isValidPlacas = false,
  setIsValidPlacas = () => {},
}) => {
  useEffect(() => {
    const test = value.length > 0;
    setIsValidPlacas(test);

    onChange(value);
  }, [value]);

  const handleInputChange = (newValue: string) => {
    const numericValue = newValue.replace(/[^0-9]/g, "");
    onChange(numericValue);
  };

  return (
    <div
      className={`${styles.input} ${isValidPlacas && styles.isValidName} ${className}`}
    >
      <img
        src={isValidPlacas ? solarPanelIconGreen : solarPanelIcon}
        alt="Pessoa icone"
      />
      <InputMask
        type="text"
        placeholder="Quantas placas tem sua usina?"
        mask=""
        maskChar={null}
        value={value}
        onChange={(evt) => handleInputChange(evt.target.value)}
      />
    </div>
  );
};

export default InputQtdPlacas;
