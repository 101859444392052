import styles from './CtaButton.module.css';

interface CtaButtonProps {
    label: string;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    disable: boolean;
    className: string;
}

const CtaButton = ({
    label = "",
    onClick = () => { },
    disable = false,
    className = ""
}: CtaButtonProps) => {
    return <button
        onClick={disable ? () => {} : onClick}
        className={`${styles.button} ${className} ${disable && styles.disable}`}
        disabled={disable}
    >
        {label}
        <div className={styles.arrow} />
    </button>;
};

export default CtaButton;