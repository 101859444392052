import { createContext, ReactNode } from "react";
import { toast } from "react-toastify";
import * as APIProposta from "../services/APIProposta";
import { navigateToExternal } from "../utils";

export interface FormData {
    name?: string;
    phone?: string;
    capex?: number;
    city?: string | null;
    state?: string;
    parameterValue?: number;
    parameterName?: string;
}

interface InputPropostaContextType {
    sendForm: (
        formData?: FormData,
        TOKEN_API?: string,
        successMsg?: string | null,
    ) => Promise<unknown>;
}

const InputPropostaContext = createContext<InputPropostaContextType>({
    sendForm: () => Promise.resolve(),
});


interface InputPropostaProviderProps {
    children: ReactNode;
}

export const InputPropostaProvider: React.FC<InputPropostaProviderProps> = ({
    children,
}) => {
    const sendForm = (
        {
            name = "",
            phone = "",
            capex = 0,
            city,
            state,
            parameterValue = 0,
            parameterName = "",
        }: FormData = {},
    ): Promise<unknown> => {
        const formData: FormData = {
            name: name,
            phone: phone.replace(/\D/g, ""),
            capex: capex / 100,
            city: city,
            state: state,
            parameterValue: parameterValue,
            parameterName: parameterName,
        };
        const data = APIProposta.createProposta(formData).then((res) => {
            
            const propostaId = res.data.uuid;
            navigateToExternal(
                `https://investidor.plinenergia.com.br/?id=${propostaId}`
            );
        })

        return toast.promise(
            data,
            {
                pending: "Enviando...",
                success: "Enviado com sucesso!",
                error: "Ocorreu um erro, por favor tente mais tarde!",
            },
        );
    };

    return (
        <InputPropostaContext.Provider
            value={{
                sendForm,
            }}
        >
            {children}
        </InputPropostaContext.Provider>
    );
};

export default InputPropostaContext;
