import { LoginProvider } from "./LoginContext";
import { PropostaProvider } from "./PropostaContext";
import { CityStatePropostaProvider } from "./CityStatePropostaContext";
import { InputPropostaProvider } from "./InputPropostaContext";
import React from "react";
import { ToastContainer } from "react-toastify";

const searchParams = new URLSearchParams(window.location.search);
const propostaId = searchParams.get('id');

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
    return (
        <React.StrictMode>
            <ToastContainer />
            <LoginProvider>
                <PropostaProvider propostaId={propostaId}>
                    <CityStatePropostaProvider>
                        <InputPropostaProvider>
                            {children}
                        </InputPropostaProvider>
                    </CityStatePropostaProvider>
                </PropostaProvider>
            </LoginProvider>
        </React.StrictMode>
    )
}