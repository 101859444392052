import React, { useContext, useEffect, useState } from "react";
import styles from "./Formulario.module.css";
import SimboloPlin from "../../assets/simbolo-plin.png"
import InputName from "../../components/inputName/InputName";
import InputWhatsapp from "../../components/inputWhatsapp/InputWhatsapp";
import InputCapex from "../../components/inputCapex/InputCapex";
import SelectSearchable from "../../components/selectSearchable/SelectSearchable";
import { ToastContainer, toast } from 'react-toastify';
import { LocalidadeContext } from "../../context/LocalidadeContext";
import 'react-toastify/dist/ReactToastify.css';
import { CityStatePropostaContext } from "../../context/CityStatePropostaContext";
import InputPower from "../../components/inputPower/InputPower";
import InputQtdPlacas from "../../components/inputQtdPlacas/InputQtdPlacas";
import Checkbox from "../../components/checkbox/Checkbox";
import CtaButton from "../../components/ctaButton/CtaButton";
import InputPropostaContext from "../../context/InputPropostaContext";

interface Characteristic {
    label: string;
    value: string;
}

const Formulario = ({
    btnClass = "",
    campoNome = true,
    campoTelefone = true,
    btnLabel = "Fazer proposta",
    campoCapex = true,
    campoCaracteristica = true,
    campoLocalidade = true,
    campoPlacas = true,
    campoPotencia = true,
    campoCheckbox = true,
    TokenAPI = ""
}) => {

    const {
        city,
        state,
        setCity,
        setState,
        getCities,
        getStates,
        selectCities,
        selectStates,
    } = useContext(CityStatePropostaContext);

    useEffect(() => {
        const fetchStates = async () => {
            await getStates();
        };
        fetchStates();
    }, []);

    useEffect(() => {
        if (!state) return;
        const stateValue = state.value;
        getCities([stateValue]);
    }, [state]);

    const [nome, setNome] = useState("");
    const [onValidateNome, setOnValidateNome] = useState(false);

    const [telefone, setTelefone] = useState("");
    const [onValidateTelefone, setOnValidateTelefone] = useState(false);

    const [capex, setCapex] = useState("");
    const [onValidateCapex, setOnValidateCapex] = useState(false);

    const [placas, setPlacas] = useState("");
    const [onValidatePlacas, setOnValidatePlacas] = useState(false);

    const [potencia, setPotencia] = useState("");
    const [onValidatePotencia, setOnValidatePotencia] = useState(false);

    const [checkbox, setCheckbox] = useState(false);

    const [caracteristica, setCaracteristica] = useState<Characteristic>();

    const [loading, setLoading] = useState(false);
    const { sendForm } = useContext(InputPropostaContext);

    const characteristics: Characteristic[] = [
        {
            label: "Potência de inversor",
            value: "PotenciaInversor",
        },
        {
            label: "Potência pico da usina",
            value: "PotenciaPico",
        },
        {
            label: "Quantidade de placas",
            value: "QuantidadePlacas",
        },
    ];

    const handleClickButton = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        if (loading) return;

        if (campoNome && !onValidateNome) {
            toast.warn("Por favor insira um nome!");
            return;
        }

        if (campoTelefone && !onValidateTelefone) {
            toast.warn(
                "Por favor insira um telefone valido!",
            );
            return;
        }

        if (campoCapex && (capex == null || capex == "")) {
            toast.warn("Por favor insira o CAPEX!");
            return;
        }

        if (campoLocalidade && (state == null)) {
            toast.warn("Por favor insira um estado!");
            return;
        }

        if (campoLocalidade && (city == null)) {
            toast.warn("Por favor insira um cidade!");
            return;
        }

        if (campoPlacas && !isPotencia && (placas == null || placas == "")) {
            toast.warn(
                "Por favor insira a quantidade de placas!",
            );
            return;
        }

        if (
            campoPotencia &&
            isPotencia &&
            (potencia == null || potencia == "")
        ) {
            toast.warn("Por favor insira a potência!");
            return;
        }

        if (campoCheckbox && (checkbox == false)) {
            toast.warn(
                "Por favor marque a checkbox para que possamos entrar em contato com você!"
            );
            return;
        }

        setLoading(true);

        const dataToSend = {
            name: nome,
            phone: telefone,
            capex: Number(capex),
            state: state?.value,
            city: city?.value,
            parameterName: caracteristica?.value,
            parameterValue: isPotencia
                ? Number(potencia) / 1000
                : Number(placas),
        };

        sendForm(dataToSend, TokenAPI)
            .then(() => {
                setNome("");
                setTelefone("");
                setCapex("");
                setCaracteristica(caracteristica);
                setPlacas("");
                setPotencia("");
                setCity(null);
                setState(null);
                setCheckbox(false);
            })
            .finally(() => setLoading(false));
    };

    const isPotencia =
        caracteristica?.value == "PotenciaInversor" ||
        caracteristica?.value == "PotenciaPico";

    return (
        <div className={styles.formProposta}>
            <div className="Toastify"></div>
            <div className={styles.imageContainer}>
                <div>
                </div>
            </div>
            <div className={styles.mainContainer}>
                <div className={styles.formContainer}>
                    <form action="" className={styles.form}>
                        <div className={styles.headerForm}>
                            <img
                                src={SimboloPlin}
                                alt="Simbolo Plin"
                                className={styles.simboloPlin}
                            />
                            <p>
                                Insira os dados obrigatórios e <strong>gere sua proposta</strong>
                            </p>
                        </div>
                        <div className={styles.inputContainer}>
                            <div className={styles.inputForm}>
                                <InputName

                                    className={styles.input}
                                    onChange={setNome}
                                    value={nome}
                                    isValidName={onValidateNome}
                                    setIsValidName={setOnValidateNome}
                                />
                                <InputWhatsapp
                                    className={styles.input}
                                    onChange={setTelefone}
                                    whatsapp={telefone}
                                    isValidWhatsapp={onValidateTelefone}
                                    setIsValidWhatsapp={setOnValidateTelefone}
                                />
                                <InputCapex
                                    className={styles.input}
                                    onChange={setCapex}
                                    value={capex}
                                    isValidCapex={onValidateCapex}
                                    setIsValidCapex={setOnValidateCapex}
                                />
                                {campoLocalidade && (
                                    <div className={styles.inputsRegiao}>
                                        <SelectSearchable
                                            value={state}
                                            options={selectStates}
                                            placeholder={"Estado"}
                                            onChange={(evt) => setState(evt)}
                                            isSearchable={false}
                                            className={styles.selectEstado}
                                        />
                                        <SelectSearchable
                                            value={city}
                                            options={selectCities}
                                            placeholder={"Cidade"}
                                            onChange={(evt) => setCity(evt)}
                                            isSearchable={true}
                                            isDisabled={!state}
                                            className={styles.selectCidade}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className={styles.inputForm}>
                                <SelectSearchable
                                    value={caracteristica}
                                    options={characteristics}
                                    placeholder={
                                        "Selecione uma característica da sua usina"
                                    }
                                    onChange={(evt) => setCaracteristica(evt)}
                                    isSearchable={true}
                                    className={styles.selectCidade}
                                />
                                {campoPotencia && isPotencia && (
                                    <InputPower
                                        className={styles.input}
                                        onChange={setPotencia}
                                        value={potencia}
                                        isValidPlacas={onValidatePotencia}
                                        setIsValidPlacas={setOnValidatePotencia}
                                    />
                                )}
                                {campoPlacas && !isPotencia && (
                                    <InputQtdPlacas
                                        className={styles.input}
                                        onChange={setPlacas}
                                        value={placas}
                                        isValidPlacas={onValidatePlacas}
                                        setIsValidPlacas={setOnValidatePlacas}
                                    />
                                )}

                                {campoCheckbox && (
                                    <Checkbox
                                        check={checkbox}
                                        label="Aceito receber comunicações da PLIN energia por WhatsApp"
                                        className={styles.checkbox}
                                        onChange={setCheckbox}
                                    />
                                )}

                                <CtaButton
                                    className={`${styles.cta} ${btnClass}`}
                                    onClick={handleClickButton}
                                    label={btnLabel}
                                    disable={loading}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div >
    )
}

export default Formulario;