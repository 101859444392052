import axios from "axios";
import { FormData } from "../context/InputPropostaContext";
import { LoginData } from "../context/LoginContext";

const BASE_URL = "https://plin-api-proposta-gerador-202823bb70f7.herokuapp.com";

/*
//TODO: Usar quando tiver proposta interna
const token = localStorage.getItem('authToken');

export const createAdminProposta = async (data: (FormData)) => {
    return await axios.post(`${BASE_URL}/admin/proposta`, data, {
        headers: {
            Authorization: `${token}`
        },
    })
        .then(response => {
          
        })
        .catch(error => {
        })
}
*/

export const getStates = async () => {
    return await axios.get(`${BASE_URL}/estados`);
};

export const getCities = async (state: string[]) => {
    return await axios.get(`${BASE_URL}/estados/${state}/cidades`);
};

export const createProposta = async (data: (FormData)) => {
    return await axios.post(`${BASE_URL}/proposta/`, data);
};

export const loginProposta = async (data: (LoginData)) => {
    return await axios.post(`${BASE_URL}/login`, data);
}
