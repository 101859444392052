import React, { useEffect } from "react";
import styles from "./InputCapex.module.css";

import InputMask from "react-input-mask";

import coinIcon from "../../assets/icons/coins-icon.svg";
import coinGreenIcon from "../../assets/icons/coins-icon-green.svg";

interface InputCapexProps {
  value?: string;
  onChange?: (value: string) => void;
  className?: string;
  isValidCapex?: boolean;
  setIsValidCapex?: (isValid: boolean) => void;
}

const InputCapex: React.FC<InputCapexProps> = ({
  value = "",
  onChange = () => {},
  className = "",
  isValidCapex = false,
  setIsValidCapex = () => {},
}) => {
  useEffect(() => {
    const test = value.length > 0;
    setIsValidCapex(test);
    onChange(value);
  }, [value]);

  const handleInputChange = (newValue: string) => {
    const numericValue = newValue.replace(/[^0-9]/g, "");
    onChange(numericValue);
  };

  const formatCurrency = (value: string) => {
    if (!value) {
      return "";
    }
    const numericValue = parseFloat(value) / 100;
    return numericValue.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
    });
  };

  return (
    <div
      className={`${styles.input} ${isValidCapex && styles.isValidCapex} ${className}`}
    >
      <img src={isValidCapex ? coinGreenIcon : coinIcon} alt="Pessoa icone" />
      <input
        type="text"
        placeholder="Qual o CAPEX da usina? (total investido) *"
        value={formatCurrency(value)}
        onChange={(evt) => handleInputChange(evt.target.value)}
      />
    </div>
  );
};

export default InputCapex;
