import { ReactNode, createContext } from "react";
import * as APIProposta from "../services/APIProposta";
import { toast } from "react-toastify";

export interface LoginData {
    login: string;
    pass: string;
}

interface LoginContextType {
    sendForm: (
        formData: LoginData
    ) => Promise<string>;
}

const LoginContext = createContext<LoginContextType>({
    sendForm: () => Promise.resolve(""),
});

interface LoginProviderProps {
    children: ReactNode;
}

export const LoginProvider: React.FC<LoginProviderProps> = ({ children }) => {
    const sendForm = ({ login = "", pass = "" }: LoginData): Promise<string> => {
        const formData: LoginData = {
            login: login,
            pass: pass,
        };

        return new Promise((resolve, reject) => {
            APIProposta.loginProposta(formData)
                .then((res) => {
                    const token = res.data.token;
                    localStorage.setItem('authToken', token)
                    resolve(token);
                })
                .catch((error) => {
                    console.error(error);
                    reject("Ocorreu um erro, porfavor tente mais tarde")
                });
        });
    };

    return (
        <LoginContext.Provider
            value={{
                sendForm,
            }}
        >
            {children}
        </LoginContext.Provider>
    );
};

export default LoginContext;
