import React from 'react';
import styles from "./SelectSearchable.module.css";
import Select from "react-select";
import { FieldName, FieldValues, UseFormRegisterReturn } from "react-hook-form";

interface InputSelectParams {
  register?: UseFormRegisterReturn<FieldName<FieldValues>>;
  value: any | boolean,
  options: any,
  placeholder?: string,
  onChange(newValue: any): void,
  isSearchable?: boolean,
  isDisabled?: boolean,
  className?: string,
}

const InputSelect: React.FC<InputSelectParams> = ({
  register = {},
  value = "",
  options = [],
  placeholder = "",
  onChange,
  isSearchable = true,
  isDisabled = false,
  className = "",
}) => {
  return (
    <Select
      {...register}
      options={options}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className={`${className} ${styles.select} ${value && styles.selected}`}
      isSearchable={isSearchable}
      isDisabled={isDisabled}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          height: "6vh",
          paddingLeft: "12px",
          paddingRight: "12px",
          border: "2px solid #cfcfcf",
          fontSize: ".9rem",
          color: "#575757",
          outline: "none",
          borderRadius: "8px",
        }),
        indicatorSeparator: () => ({
          display: "none",
        }),
        indicatorsContainer: (baseStyles) => ({
          ...baseStyles,
          color: "#03053D",
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          zIndex: 99999,
        }),
      }}

    />
  );
};

export default InputSelect;
