import React, { useEffect } from 'react';
import InputMask from 'react-input-mask';

import styles from './InputName.module.css';
import person from '../../assets/icons/person.webp';
import personVerde from '../../assets/icons/person-verde.webp';

interface InputNameProps {
    value: string;
    onChange: (value: string) => void;
    className?: string;
    isValidName: boolean;
    setIsValidName: (isValid: boolean) => void;
    placeholder?: string;
    type?: string;
    img?: string;
    imgGreen?: string;
}

const InputName: React.FC<InputNameProps> = ({
    value = '',
    onChange = () => { },
    className = '',
    isValidName = false,
    setIsValidName = () => { },
    placeholder = 'Qual o seu nome?',
    type = 'text',
    img = person,
    imgGreen = personVerde
}) => {

    useEffect(() => {
        const isValid = value.trim().length > 0;
        setIsValidName(isValid);
        onChange(value);
    }, [value, setIsValidName, onChange]);

    return (
        <div className={`${styles.input} ${isValidName && styles.isValidName} ${className}`}>
            <img src={isValidName ? imgGreen : img} alt="Pessoa icone" />
            <InputMask
                type={type}
                placeholder={placeholder}
                mask=""
                maskChar={null}
                value={value}
                onChange={(evt) => onChange(evt.target.value)}
            />
        </div>
    );
};

export default InputName;
